<template>
  <div style="display: flex; align-items: center">
    <div class="btnItem" @click="openCreate">
      <img :src="addImgSrc" />
      <span>{{ addName }}</span>
    </div>
    <div
      class="btnItem"
      v-show="$route.path === '/manage'"
      @click="openIdCreate"
    >
      <img :src="idImgSrc" />
      <span>{{ idAddName }}</span>
    </div>
    <div class="btnItem" @click="refresh">
      <img :src="refreshImgSrc" />
      <span>{{ refreshName }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "buttons",
  data() {
    return {
      addName: "新建",
      addImgSrc: require("./add.png"),
      idAddName: "导入",
      idImgSrc: require("./idcreate.png"),
      refreshName: "刷新",
      refreshImgSrc: require("./refresh.png"),
      createFlag: false,
      createCrowdData: {},
    };
  },
  computed: {
    ...mapGetters(["role"]),
  },
  methods: {
    openCreate() {
      this.$emit("createCrowd");
    },
    openIdCreate() {
      this.$emit("idCreateCrowd");
    },
    refresh() {
      this.$emit("refresh");
    },
  },
};
</script>

<style scoped lang="scss">
.btnItem {
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;

  img {
    margin-right: 5px;
  }

  &:first-child {
    margin-left: 0;
  }
}
</style>
