import request, {
  applicationJson,
  uploadFile,
  downloadFile,
} from "@/utils/axios";

export function initData(params) {
  return request.get("/user/list", params);
}

export function SubmitFormData(params) {
  return applicationJson.post("/user/info", params);
}

export function getSovBrandAll(params) {
  return request.get("/sov/brand/all", params);
}

export function myUpload(params) {
  return uploadFile("/user/campaign/brand/logo", params);
}

export function dashboardUpload(params) {
  return uploadFile("/user/brand/price/excel", params);
}

export function downloadTemplate() {
  return downloadFile("/user/brand/price/temporary", null, "品牌价格模版.xlsx");
}

// 获取seeding score 模版 /seedingScore/template
export function downloadSeedingScoreTemplate(params) {
  return downloadFile("/seedingScore/template", params, "seeding score模版.xlsx");
}
// 上传seeding score 文件 /seedingScore/file
export function uploadSeedingScoreFile (params) {
  return request.post('/seedingScore/file', params)
}

// 提交seeding score 文件
export function submitSeedingScoreFile (params) {
  return request.post('/seedingScore/file/submission', params)
}
export function queryAllDisplayMenuList(){
  return request.get('/user/queryAllDisplayMenuList')
}


 

