<template>
  <div class="adminDiv">
    <tool-bar>
      <template #select>
        <el-input
          placeholder="请输入内容"
          v-model="searchContent"
          size="small"
          clearable
          @clear="initData"
          @keyup.enter="initData"
          style="width: 360px"
        >
          <template #prepend>
            <el-select
              v-model="searchField"
              placeholder="请选择"
              style="width: 100px"
              @change="handleSearchField"
              class="inputPreSelect"
            >
              <el-option label="登录名" value="operatorName"></el-option>
              <el-option label="公司" value="companyName"></el-option>
              <el-option label="部门" value="department"></el-option>
            </el-select>
          </template>
          <template #append>
            <el-button icon="el-icon-search" @click="initData"></el-button>
          </template>
        </el-input>
      </template>
      <template #checkBox>
        <el-checkbox v-model="checked" style="margin-left: 30px">
          不显示已禁用用户
        </el-checkbox>
      </template>
      <template #imgClick>
        <buttons-com @createCrowd="createCrowd" @refresh="initData" />
      </template>
    </tool-bar>
    <div style="overflow: auto; flex: 1">
      <el-table
        :data="tableData"
        stripe
        v-loading="tableLoading"
        @sort-change="handleSort"
        :default-sort="{ prop: 'createTimeStr', order: 'descending' }"
      >
        <el-table-column
          v-for="item in colData"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :sortable="item.sortable"
        />
        <el-table-column prop="status" label="状态" width="70">
          <template #default="{ row }">
            <el-button
              type="text"
              @click="handleStatusClick(row)"
              :disabled="!row.modifyFlag"
            >
              {{ row.status === 1 ? "正常" : "已禁用" }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="修改" width="56">
          <template #default="{ row }">
            <el-button
              type="text"
              :disabled="!row.modifyFlag"
              @click="modifyCrowd(row)"
            >
              修改
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      layout="prev, pager, next"
      :page-size="pageSize"
      :total="totalSize"
      :current-page="page"
      style="text-align: center; margin-top: 10px"
      @current-change="handlePage"
    >
    </el-pagination>
    <create-account
      v-model="createFlag"
      :initData="formData"
      @openConfirm="openConfirm"
      :title="title"
      :modifyOperatorName="modifyOperatorName"
      :crowdList="crowdList"
    />
    <error-dialog
      v-model="statusFlag"
      :cancel="cancelBtn"
      @confirmClick="submitStatus"
    >
      是否更改状态？
    </error-dialog>
    <error-dialog v-model="tipsFlag" @confirmClick="confirmSubmit">
      {{ tipsText }}
    </error-dialog>
  </div>
</template>

<script>
import toolBar from "@/components/toolBar";
import buttonsCom from "@/components/common/crowdButtons";
import createAccount from "./createAccount/createAccount";
import errorDialog from "@/components/errorDialog";

import { initData, SubmitFormData, getSovBrandAll } from "@/api/admin";
import { getCampaignBrand } from "@/api/campaign";
import { mapGetters } from "vuex";

export default {
  name: "admin",
  data() {
    return {
      tableData: [],
      tempTableData: [],
      tempCrowdList: [],
      disableTableData: [],
      colData: [
        {
          prop: "operatorName",
          label: "登录名",
          width: null,
          sortable: false,
        },
        {
          prop: "companyName",
          label: "公司",
          width: null,
          sortable: false,
        },
        {
          prop: "department",
          label: "部门",
          width: null,
          sortable: false,
        },
        {
          prop: "email",
          label: "邮箱",
          width: null,
          sortable: false,
        },
        {
          prop: "tel",
          label: "电话",
          width: null,
          sortable: false,
        },
        {
          prop: "createTimeStr",
          label: "创建时间",
          width: 115,
          sortable: "custom",
        },
        {
          prop: "maxConn",
          label: "最大在线数",
          width: null,
          sortable: false,
        },
        {
          prop: "maxCrowd",
          label: "新建人群数",
          width: null,
          sortable: false,
        },
        {
          prop: "expireTimeStr",
          label: "失效时间",
          width: 115,
          sortable: false,
        },
        {
          prop: "allowIps",
          label: "IP绑定",
          width: null,
          sortable: false,
        },
        {
          prop: "role",
          label: "角色",
          width: 100,
          sortable: false,
        },
      ],
      createFlag: false,
      statusFlag: false,
      cancelBtn: true,
      tipsFlag: false,
      tipsText: "",
      initFormData: {
        id: "",
        operatorName: "",
        password: "",
        companyName: "",
        department: "",
        email: "",
        tel: "",
        expireTimeStr: "",
        role: "",
        sites: [
          "wechat",
          "weibo",
          "douyin",
          "xiaohongshu",
          "bilibili",
          "kuaishou",
        ],
        menus: [],
        maxConn: "",
        crowdList: [],
        selCrowdList: [],
        wechatNeeded: 1,
        wechatMax: 1,
        allowIps: "",
        maxCrowd: "",
        collectionKolMax: "",
        campaignEvaluationQuota: "",
        brand: [],
        sovBrandList: [],
        campaignBrandList: [],
        campaignBrand: [],
        competitionBrandList: [],
        isSelSov: true,
        isSelCampaignBrand: true,
        brandCoop: 1,
        uploadBrandPriceExcelPath: "",
        seedingScoreFile: "",
      },
      formData: {},
      title: "",
      modifyOperatorName: true,
      tableLoading: true,
      // 新增
      searchField: "operatorName",
      searchContent: "",
      page: 1,
      pageSize: 10,
      totalSize: 1000,
      sortColumn: "create_time",
      // asc正序；desc降序
      sortDirection: "desc",
      crowdList: [],
      checked: false,
      statusChange: 0,
    };
  },
  computed: {
    ...mapGetters(["role"]),
    params() {
      return {
        searchField: this.searchField,
        searchContent: this.searchContent,
        page: this.page,
        size: this.pageSize,
        sortColumn: this.sortColumn,
        sortDirection: this.sortDirection,
        status: this.statusChange,
      };
    },
  },
  mounted() {
    if (this.role !== 1) {
      this.$router.push("/homeIndex");
    } else {
      this.initData();
      this.getBrandAll();
      this.getCampaignBrand();
    }
  },
  methods: {
    initData() {
      this.tableLoading = true;
      this.page = 1;
      initData(this.params).then((res) => {
        if (!res.code) {
          this.totalSize = res.data.totalNum;
          this.crowdList = res.data.crowdList;
          this.tableData = res.data.userList;
          this.disableTableData = res.data.userList;
          this.tempTableData = res.data.userList;
          this.tempCrowdList = res.data.crowdList;
        }
        this.tableLoading = false;
      });
    },
    async getBrandAll() {
      let res = await getSovBrandAll();
      if (res.code === 0) {
        res.data.forEach((v) => {
          this.initFormData.brand.push({
            label: v.brand,
            value: v.brandId,
          });
        });
      }
    },
    async getCampaignBrand() {
      let res = await getCampaignBrand();
      if (res.code === 0) {
        res.data.forEach((v) => {
          this.initFormData.campaignBrand.push({
            label: v.brandName,
            value: v.brandName,
          });
        });
      }
    },
    createCrowd() {
      this.formData = Object.assign({}, this.initFormData);
      this.crowdList = [...this.tempCrowdList];
      this.title = "新增操作员";
      this.modifyOperatorName = true;
      this.createFlag = true;
    },
    modifyCrowd(row) {
      let tempData = this.tempTableData.find((v) => {
        return v.id === row.id;
      });
      let tempCrowds = [...this.tempCrowdList, ...tempData.userCrowdList];
      // 去重 peopleId
      let res = new Map();
      tempCrowds = tempCrowds.filter(
        (a) => !res.has(a.peopleId) && res.set(a.peopleId, 1)
      );
      this.crowdList = tempCrowds;
      // 点击修改
      // 更新数据否则不更新修改界面
      let tempBrandList = [];
      this.formData = Object.assign({ uploadBrandPriceExcelPath: "" }, row);
      this.formData.brand = this.initFormData.brand;
      this.formData.campaignBrandList.forEach((v) => {
        tempBrandList.push({
          label: v,
          value: v,
        });
      });
      this.formData.campaignBrand = tempBrandList;
      this.title = "修改操作员";
      this.modifyOperatorName = false;
      this.createFlag = true;
      this.formData.isSelSov = !this.formData.menus.includes(310);
      this.formData.crowdList = tempData.userCrowdList.map((v) => v.peopleId);
    },
    handleStatusClick(row) {
      let status = row.status;
      if (status === 1) {
        status = 0;
      } else {
        status = 1;
      }
      this.formData = Object.assign({}, row, { status });
      this.statusFlag = true;
    },
    async submitStatus() {
      // 发送更改状态请求
      const { code } = await SubmitFormData(this.formData);
      if (code === 0) {
        this.statusFlag = false;
        this.initData();
      }
    },
    openConfirm(code) {
      switch (code) {
        case 0:
          this.tipsText = "数据提交成功";
          this.initFormData.campaignBrand = [];
          this.getCampaignBrand();
          break;
        case 1:
          this.tipsText = "登录名已存在";
          break;
      }
      this.tipsFlag = true;
    },
    confirmSubmit() {
      this.tipsFlag = false;
      if (this.tipsText === "数据提交成功") {
        this.initData();
      }
    },
    handlePage(page) {
      this.page = page;
      this.tableLoading = true;
      initData(this.params).then((res) => {
        if (!res.code) {
          this.totalSize = res.data.totalNum;
          this.crowdList = res.data.crowdList;
          this.tableData = res.data.userList;
          this.disableTableData = res.data.userList;
          this.tempTableData = res.data.userList;
          this.tempCrowdList = res.data.crowdList;
        }
        this.tableLoading = false;
      });
    },
    handleSearchField() {
      this.searchContent = "";
      this.initData();
    },
    handleSort({ order }) {
      this.sortDirection = order ? order.slice(0, -6) : "";
      this.initData();
    },
  },
  watch: {
    checked(val) {
      if (val) {
        this.statusChange = 1;
      } else {
        this.statusChange = 0;
      }
      this.initData();
    },
  },
  components: {
    buttonsCom,
    createAccount,
    errorDialog,
    toolBar,
  },
};
</script>

<style lang="scss">
.inputPreSelect {
  .el-input__prefix,
  .el-input__suffix {
    top: -5px !important;
  }
}
</style>
<style scoped lang="scss">
.adminDiv {
  height: 100%;
  background-color: #fff;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}
</style>
