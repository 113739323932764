<template>
  <el-dialog
    custom-class="adminDialogClass"
    v-model="flag"
    :title="title"
    width="80%"
    destroy-on-close
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      label-width="110px"
      ref="formData"
      :rules="rules"
      size="mini"
      status-icon
    >
      <p class="pLabel" style="margin-top: 3px">基本信息：</p>
      <el-row :gutter="80">
        <el-col :span="12">
          <el-form-item label="登录名" prop="operatorName">
            <el-input
              v-model="formData.operatorName"
              placeholder="请输入用户名，只能包含数字，字母和下划线或者为邮箱"
              :disabled="!modifyOperatorName"
              maxlength="255"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="formData.password"
              placeholder="请输入密码，不能包含中文，中文符号和空格"
              maxlength="255"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="80">
        <el-col :span="12">
          <el-form-item label="公司" prop="companyName">
            <el-input
              v-model="formData.companyName"
              placeholder="请输入公司名称"
              maxlength="255"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="部门" prop="department">
            <el-input
              v-model="formData.department"
              placeholder="请输入部门"
              maxlength="255"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="80">
        <el-col :span="12">
          <el-form-item label="邮箱" prop="email">
            <el-input
              v-model="formData.email"
              placeholder="请输入正确的邮箱地址"
              maxlength="255"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电话" prop="tel">
            <el-input
              v-model="formData.tel"
              placeholder="请输入正确的电话号码"
              maxlength="11"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="80">
        <el-col :span="12">
          <el-form-item label="有效期" prop="expireTimeStr">
            <el-date-picker
              v-model="formData.expireTimeStr"
              type="date"
              placeholder="选择有效期"
              :editable="false"
              style="width: 300px"
              :disabled-date="disabledDate"
              format="YYYY年MM月DD日"
              value-format="YYYY-MM-DD"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="角色" prop="role">
            <el-select
              v-model="formData.role"
              placeholder="请选择角色"
              @change="handleRole"
            >
              <el-option
                v-for="item in roleOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <p class="pLabel">授权信息：</p>
      <el-row :gutter="80">
        <el-col :span="12">
          <el-form-item label="可查看平台" prop="sites">
            <el-checkbox-group v-model="formData.sites" :min="1">
              <el-checkbox
                v-for="item in siteOptions"
                :key="item.value"
                :label="item.value"
              >
                {{ item.label }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="可查看菜单" prop="menus">
            <el-cascader
              style="width: 100%"
              v-model="formData.menus"
              :options="menuOptions"
              :props="menuProps"
              :show-all-levels="false"
              collapse-tags
              @change="handleMenu"
            >
            </el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="80">
        <el-col :span="12">
          <el-form-item label="最大在线数" prop="maxConn">
            <el-input-number
              step-strictly
              :controls="false"
              placeholder="请输入最大在线数，默认为1"
              min="1"
              max="9999999999"
              v-model="formData.maxConn"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="预置人群" prop="crowdList">
            <el-select
              v-model="formData.crowdList"
              multiple
              collapse-tags
              placeholder="请选择"
            >
              <el-option
                v-for="item in crowdList"
                :key="item.peopleId"
                :label="item.crowdName"
                :value="item.peopleId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="80">
        <el-col :span="12">
          <el-form-item label="绑定微信认证">
            <el-radio-group v-model="formData.wechatNeeded">
              <el-radio :label="1" style="margin-left: 10px">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="允许微信数量" prop="wechatMax">
            <el-input-number
              step-strictly
              :controls="false"
              placeholder="请输入允许绑定微信数量，默认为1"
              min="0"
              max="9999999999"
              v-model="formData.wechatMax"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="80">
        <el-col :span="12">
          <el-form-item prop="allowIps" label="接入IP限制">
            <el-input
              type="textarea"
              resize="none"
              :rows="3"
              v-model="formData.allowIps"
              placeholder="输入IP或IP地址段，之间用英文逗号分隔，如10.153.1.1,111.222.93.225/28,111.222.93.101-111.222.93.108"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="新建人群数" prop="maxCrowd">
            <el-input-number
              step-strictly
              :controls="false"
              placeholder="请输入新建人群数量"
              min="0"
              max="9999999999"
              v-model="formData.maxCrowd"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <!-- seeding score 配置 -->
      <el-row :gutter="80">
        <el-col :span="12">
          <el-form-item label="seeding score" width="140px">
            <div style="position: relative">
              <el-upload
                class="dashboard_upload"
                ref="seedingScoretemplateUpload"
                :limit="1"
                :headers="{ token }"
                :action="seedingScoreAction"
                :on-remove="removeSeedingScoreFile"
                :on-error="seedingScoreFileOnError"
                :on-success="seedingScoreFileOnSuccess"
              >
                <el-button type="primary">点击上传 </el-button>
              </el-upload>
              <el-button
                type="text"
                @click="downloadSeedingScoreTemplateFunc"
                size="mini"
                style="position: absolute; top: 0; left: 100px"
                >下载上传模版
              </el-button>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <p class="pLabel">KOL详情：</p>
      <el-row :gutter="80">
        <el-col :span="12">
          <el-form-item label="查看合作品牌" prop="wechatMax">
            <el-radio-group v-model="formData.brandCoop">
              <el-radio :label="1" style="margin-left: 10px">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>

      <p class="pLabel">投放：</p>
      <el-row :gutter="80">
        <el-col :span="12">
          <el-form-item label="添加KOL额度" prop="collectionKolMax">
            <el-input-number
              step-strictly
              :controls="false"
              :disabled="isSelCol"
              min="0"
              max="9999999999"
              placeholder="常用达人添加KOL最大额度,不填额度为无限"
              v-model="formData.collectionKolMax"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="添加后测额度" prop="campaignEvaluationQuota">
            <el-input-number
              step-strictly
              :controls="false"
              :disabled="isSelCol"
              min="0"
              max="9999999999"
              placeholder="活动管理添加后测最大额度,不填额度为无限"
              v-model="formData.campaignEvaluationQuota"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="80">
        <el-col :span="12">
          <el-form-item label="活动品牌">
            <el-table
              :data="tableData"
              border
              style="width: 100%"
              class="table_container"
            >
              <template #empty>
                <el-button
                  :disabled="isSelCol"
                  type="primary"
                  @click="addNewBrand"
                >
                  添加品牌
                </el-button>
              </template>
              <el-table-column align="center" label="" width="50">
                <template #default="{ row }">
                  <el-tooltip effect="dark" content="添加" placement="top">
                    <i
                      v-if="
                        tableData.length &&
                        row.id === tableData[tableData.length - 1].id
                      "
                      class="el-icon-plus"
                      style="
                        font-size: 16px;
                        font-weight: 600;
                        vertical-align: middle;
                        color: #51a6f0;
                        cursor: pointer;
                      "
                      @click="addNewBrand"
                    ></i>
                    <i v-else></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="brandName"
                label="品牌名"
                width="180"
              >
                <template #default="{ row }">
                  <div class="cellInput">
                    <el-input
                      v-model="row.brandName"
                      placeholder="请输入品牌名"
                      maxlength="255"
                    ></el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="logoFile"
                label="品牌logo"
                width="180"
              >
                <template #default="{ row }">
                  <el-upload
                    ref="adminUpload"
                    class="avatar-uploader"
                    :multiple="false"
                    :limit="1"
                    :headers="{ token }"
                    action="/"
                    :on-remove="removeFile"
                    :on-error="brandLogoFileOnError"
                    :show-file-list="true"
                    :http-request="
                      (file) => {
                        return myUpload(file, row);
                      }
                    "
                  >
                    <img
                      v-if="row.imageUrl"
                      :src="row.imageUrl"
                      class="avatar"
                    />
                    <el-icon v-else class="avatar-uploader-icon">
                      <plus />
                    </el-icon>
                  </el-upload>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="address" label="操作">
                <template #default="{ row }">
                  <el-tooltip effect="dark" content="删除" placement="top">
                    <el-icon class="closeClass" @click="delBrand(row.id)">
                      <close-bold style="width: 16px; height: 16px" />
                    </el-icon>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="competitionClass">
          <el-form-item label="竞品品牌" prop="competitionBrandList">
            <el-select
              v-model="formData.competitionBrandList"
              filterable
              multiple
              :disabled="isSelCol"
              collapse-tags
              @change="changeSelect"
              placeholder="请选择竞品品牌"
            >
              <el-checkbox
                class="selectAll"
                v-model="competitionCheckedAll"
                @change="competitionSelectAll"
                >全选
              </el-checkbox>
              <el-option
                v-for="item in formData.brand"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <p class="pLabel">声量：</p>
      <el-row :gutter="80">
        <el-col :span="12" class="brandListClass">
          <el-form-item label="声量品牌" prop="sovBrandList">
            <el-select
              v-model="formData.sovBrandList"
              filterable
              multiple
              collapse-tags
              :disabled="formData.isSelSov"
              @change="changeSelect"
              placeholder="请选择声量品牌"
            >
              <el-checkbox
                class="selectAll"
                v-model="checkedAll"
                @change="selectAll"
                >全选
              </el-checkbox>
              <el-option
                v-for="item in formData.brand"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <p class="pLabel">美妆：</p>
      <el-form-item label="品牌价格段">
        <div style="position: relative">
          <el-upload
            class="dashboard_upload"
            ref="templateUpload"
            :multiple="false"
            :limit="1"
            :headers="{ token }"
            action="/"
            :on-remove="removeDashboardFile"
            :on-error="brandLogoFileOnError"
            :http-request="
              (file) => {
                return dashboardFileUpload(file);
              }
            "
          >
            <el-button :disabled="disabledDashboardUpload" type="primary"
              >点击上传
            </el-button>
          </el-upload>
          <el-button
            :disabled="disabledDashboardUpload"
            type="text"
            @click="downloadTemplate"
            style="position: absolute; top: 0; left: 120px"
            >下载上传模版
          </el-button>
        </div>
      </el-form-item>
      <p class="pLabel">其他：</p>
      <el-row :gutter="80">
        <el-col :span="12" class="accessKey">
          <el-form-item label="accessKey" prop="accessKey">
            <el-input
                v-model="formData.accessKey"
                placeholder="请输入accessKey"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="tenantCode">
          <el-form-item label="租户code" prop="tenantCode">
            <el-input
                v-model="formData.tenantCode"
                placeholder="请输入租户code"
                maxlength="11"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="btnDiv">
        <el-button type="primary" @click="submitForm('formData')">
          保存
        </el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {
  SubmitFormData,
  myUpload,
  downloadTemplate,
  dashboardUpload,
  uploadSeedingScoreFile,
  downloadSeedingScoreTemplate,
  queryAllDisplayMenuList
} from "@/api/admin";
import { nextTick } from "vue";
import { Plus, CloseBold } from "@element-plus/icons-vue";

export default {
  name: "create-account",
  data() {
    // 最大在线数
    // 和角色有关
    let checkmaxConn = (rule, value, callback) => {
      if (!value) {
        this.formData.maxConn = 1;
        callback();
      } else if (value > 2 && this.role !== 1) {
        callback(new Error("最多允许同时在线数为2"));
      } else {
        callback();
      }
    };
    // 可新建人群数
    let checkmaxCrowd = (rule, value, callback) => {
      if (!value) {
        this.formData.maxCrowd = 0;
      }
      callback();
    };

    let checkoperatorName = (rule, value, callback) => {
      let reg = new RegExp("^[0-9a-zA-Z_@.]{1,}$");
      if (!value) {
        callback(new Error("请输入用户名"));
      } else if (!reg.test(value)) {
        callback(new Error("用户名只能包含数字，字母和下划线或者为邮箱"));
      } else {
        callback();
      }
    };

    let checkpassWord = (rule, value, callback) => {
      let reg = new RegExp(
        "^[^！……|：、：“”《》？￥（）｛｝【】·；‘’，。、\u4e00-\u9fa5\uF900-\uFA2D\\s]{0,}$"
      );
      let regPassword = new RegExp(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
      );
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (!reg.test(value)) {
        callback(new Error("密码不能包含中文，中文符号和空格"));
      } else if (!regPassword.test(value)) {
        callback(
          new Error("密码需要8位以上，并且包含大小写字母、数字和特殊字符，")
        );
      } else {
        callback();
      }
    };

    let checkcompanyName = (rule, value, callback) => {
      if (!value || !value.trim()) {
        callback(new Error("请输入公司名称"));
      } else {
        this.formData.companyName = value.trim();
        callback();
      }
    };

    let checkCrowdList = (rule, value, callback) => {
      if (!value.length) {
        callback(new Error("至少选择一个人群"));
      } else {
        callback();
      }
    };

    let checkExpireTimeStr = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择有效期"));
      } else {
        callback();
      }
    };

    let checkwechatMax = (rule, value, callback) => {
      if (value < 0) {
        this.formData.wechatMax = "";
      }
      callback();
    };

    let checkBrandList = (rule, value, callback) => {
      if (value.length === 0 && this.formData.isSelSov === false) {
        callback(new Error("至少选择一个品牌"));
      } else {
        callback();
      }
    };

    let checkCampaignList = (rule, value, callback) => {
      if (value.length === 0 && this.formData.isSelCampaignBrand === false) {
        callback(new Error("至少选择一个品牌"));
      } else {
        callback();
      }
    };
    return {
      brandLogoAction:
        process.env.VUE_APP_API_HOST + "/user/campaign/brand/logo",
      brandLogoFilePath: "",
      tableData: [],
      formData: {},
      newId: "",
      rules: {
        operatorName: [
          { required: true, validator: checkoperatorName, trigger: "blur" },
        ],
        password: [
          { required: true, validator: checkpassWord, trigger: "blur" },
        ],
        companyName: [
          { required: true, validator: checkcompanyName, trigger: "blur" },
        ],
        email: [
          {
            required: true,
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "blur",
          },
        ],
        expireTimeStr: [
          { required: true, validator: checkExpireTimeStr, trigger: "blur" },
        ],
        role: [{ required: true, message: "请选择角色", trigger: "change" }],
        menus: [
          { required: true, message: "可查看菜单为空", trigger: "change" },
        ],
        maxConn: [{ validator: checkmaxConn, trigger: "blur" }],
        crowdList: [{ validator: checkCrowdList, trigger: "change" }],
        maxCrowd: [{ validator: checkmaxCrowd, trigger: "blur" }],
        wechatMax: [{ validator: checkwechatMax, trigger: "blur" }],
        sovBrandList: [{ validator: checkBrandList, trigger: "change" }],
        campaignBrandList: [
          { validator: checkCampaignList, trigger: "change" },
        ],
      },
      disabledDate: (time) => {
        // 一天时间戳：24 * 60 * 60 * 1000 = 86400000
        return time.getTime() < Date.now() - 86400000;
      },
      menuOptions: [
        // {
        //   value: 110,
        //   label: "榜单",
        // },
        // {
        //   value: 310,
        //   label: "声量",
        //   children: [
        //     {
        //       value: 320,
        //       label: "品牌榜单",
        //     },
        //     {
        //       value: 330,
        //       label: "产品榜单",
        //     },
        //     // {
        //     //   value: 340,
        //     //   label: "品类榜单",
        //     // },
        //     {
        //       value: 350,
        //       label: "活动洞察",
        //     },
        //     {
        //       value: 360,
        //       label: "舆情分析报告",
        //     },
        //   ],
        // },
        // {
        //   value: 410,
        //   label: "投放",
        //   children: [
        //     {
        //       value: 420,
        //       label: "常用达人",
        //     },
        //     {
        //       value: 430,
        //       label: "活动管理",
        //     },
        //     // {
        //     //   value: 440,
        //     //   label: '历史活动数据管理'
        //     // },
        //     // {
        //     //   value: 450,
        //     //   label: "报价管理",
        //     // },
        //     {
        //       value: 460,
        //       label: "供应商管理",
        //     },
        //     {
        //       value: 470,
        //       label: "仪表盘",
        //     },
        //   ],
        // },
        // {
        //   value: 610,
        //   label: "美妆",
        //   children: [
        //     {
        //       value: 620,
        //       label: "产品宣称dashboard",
        //     },
        //     {
        //       value: 630,
        //       label: "趋势追踪",
        //     },
        //     {
        //       value: 640,
        //       label: "产品库",
        //     },
        //     // {
        //     //   value: 650,
        //     //   label: "Chanel Dashboard",
        //     // },
        //   ],
        // },
        // {
        //   value: 710,
        //   label: "日志管理",
        // },
        // {
        //   value: 810,
        //   label: "CHANEL",
        //   children: [
        //     {
        //       value: 820,
        //       label: "Dashboard",
        //     },
        //   ],
        // },
      ],
      menuProps: { multiple: true },
      /* admin：管理员
         auditor：审计员
         operator：操作员
         agency：供应商
         110：榜单
         310：声量
         320：品牌榜单
         330：产品榜单
         340：品类榜单
         350：活动洞察
         410：投放
         420：常用达人
         430：活动管理
         440：历史活动数据管理
         450：报价管理
         460：供应商管理
         470：仪表盘
         610：美妆
         620：产品宣称dashboard
         710: 日志管理
         */
      roleOptions: [
        { label: "管理员", value: "admin" },
        { label: "审计员", value: "auditor" },
        { label: "操作员", value: "operator" },
        // { label: "供应商", value: "agency" },
      ],
      siteOptions: [
        { label: "微信", value: "wechat" },
        { label: "微博", value: "weibo" },
        { label: "抖音", value: "douyin" },
        { label: "小红书", value: "xiaohongshu" },
        { label: "哔哩哔哩", value: "bilibili" },
        { label: "快手", value: "kuaishou" },
      ],
      roleMenu: {
        // admin: [110, 410, 420, 430, 440, 460, 470],
        // auditor: [110, 410, 420, 430, 440, 460, 470, 710],
        // operator: [110, 410, 420, 430, 440, 460, 470],
        // agency: [410, 440, 450]
        admin: [110, 310, 320, 330, 350, 360, 410, 420, 430, 470],
        auditor: [110, 410, 420, 430, 470, 710],
        operator: [110, 410, 420, 430, 470],
        // agency: [],
      },
      isSelSov: true,
      checkedAll: false,
      competitionCheckedAll: false,
      selectedArray: [],
      chosenMenu: [],
      handleFlag: false,
      isShowUpload: false,
      // 控制投放权限，暂时关闭
      isSelCol: false,
      // 美妆是否可以上传
      disabledDashboardUpload: true,
      seedingScoreAction: process.env.VUE_APP_API_HOST + "/seedingScore/file",
    };
  },
  props: {
    initData: {
      type: Object,
      default() {
        return {};
      },
    },
    title: {
      type: String,
      default: "新增操作员",
    },
    modifyOperatorName: {
      type: Boolean,
      default: true,
    },
    crowdList: {
      type: Array,
      default() {
        return [];
      },
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    ...mapGetters(["role", "sites", "token"]),
    flag: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    async getMenu(){
      let { code,data } = await queryAllDisplayMenuList()
      if(code === 0){
        data.forEach(item => {
          this.menuOptions.push({
            value: item.id,
            label: item.menuName,
            children: item.children.map(child => {
              return {
                value: child.id,
                label: child.menuName,
              }
            })
          })
        })
      }
    },
    async myUpload(params, row) {
      let res = await myUpload(params.file);
      if (res.code === 0) {
        row.logoFile = res.data.replace(/\\/g, "/").split("/").pop();
        row.imageUrl = URL.createObjectURL(params.file);
        // this.imageUrl = URL.createObjectURL(params.file);
        this.$message({
          type: "success",
          message: "上传logo成功",
        });
      } else {
        this.$message.error("上传失败，请联系管理员!");
      }
    },
    removeFile(file, fileList) {
      if (fileList.length === 0) {
        this.imageUrl = "";
      }
    },
    brandLogoFileOnError() {},
    addNewBrand() {
      this.isShowUpload = true;
      if (this.tableData.length === 0) {
        this.newId = this.tableData.length + 1;
      } else {
        this.newId = this.tableData[this.tableData.length - 1].id + 1;
      }
      const bool = this.tableData.some((v) => v.brandName === "");
      if (bool) {
        this.$message({
          type: "info",
          message: "请填写品牌名称",
        });
        return;
      }
      this.tableData.push({
        id: this.newId,
        brandName: "",
        logoFile: "",
      });
    },
    delBrand(id) {
      const ids = this.tableData.findIndex((v) => v.id === id);
      this.tableData.splice(ids, 1);
    },
    selectAll() {
      this.formData.sovBrandList = [];
      if (this.checkedAll) {
        this.formData.brand.map((item) => {
          this.formData.sovBrandList.push(item.value);
        });
      } else {
        this.formData.sovBrandList = [];
      }
    },
    competitionSelectAll() {
      this.formData.competitionBrandList = [];
      if (this.competitionCheckedAll) {
        this.formData.brand.map((item) => {
          this.formData.competitionBrandList.push(item.value);
        });
      } else {
        this.formData.competitionBrandList = [];
      }
    },
    changeSelect(val) {
      if (val.length === this.formData.brand.length) {
        this.checkedAll = true;
      } else {
        this.checkedAll = false;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 异步操作，关闭form表单，打开确认对话框
          if (this.formData.collectionKolMax === null) {
            this.formData.collectionKolMax = "";
          }
          if (this.formData.campaignEvaluationQuota === null) {
            this.formData.campaignEvaluationQuota = "";
          }
          this.tableData.forEach((v) => {
            if (v.logoFile.includes("logo")) {
              v.logoFile = v.logoFile.replace(/\\/g, "/").split("/").pop();
            }
          });
          this.formData.campaignBrandList = this.tableData;
          // 格式化menu值
          // this.formData.menus = this.chosenMenu;
          if (this.handleFlag) {
            this.formData.menus = this.chosenMenu;
            this.handleFlag = false;
          }
          SubmitFormData(this.formData).then((res) => {
            if (res.code !== 0) {
              this.$message.error("请求失败，请联系管理员");
            } else {
              this.$emit("openConfirm", res.code);
            }
            if (this.$refs.adminUpload) {
              this.$refs.adminUpload.clearFiles();
            }
            this.$emit("update:modelValue", false);
          });
        } else {
          return false;
        }
      });
    },
    handleMenu(value) {
      this.handleFlag = true;
      // value拍平去重
      const val = Array.from(new Set(value.flat()));
      this.chosenMenu = val;
    },
    handleRole(role) {
      this.chosenMenu = this.roleMenu[role];
      this.formData.menus = this.roleMenu[role];
    },
    downloadTemplate() {
      downloadTemplate();
    },
    async downloadSeedingScoreTemplateFunc() {
      // seedingScoreFile
      await downloadSeedingScoreTemplate({
        fileName: this.formData.seedingScoreFile,
      });
    },
    async seedingScoreFileUpload(params) {
      const { code, data } = await uploadSeedingScoreFile({
        file: params.file,
      });
      if (code === 0) {
        this.formData.uploadSeedingScoreExcelPath = data;
        this.$message({
          type: "success",
          message: "上传seeding score文件成功",
        });
      } else {
        this.$message.error("上传失败，请联系管理员!");
      }
    },
    async dashboardFileUpload(params) {
      const { code, data } = await dashboardUpload(params.file);
      if (code === 0) {
        this.formData.uploadBrandPriceExcelPath = data;
        this.$message({
          type: "success",
          message: "上传品牌价格文件成功",
        });
      } else {
        this.$message.error("上传失败，请联系管理员!");
      }
    },
    removeSeedingScoreFile() {
      this.formData.uploadSeedingScoreExcelPath = "";
      this.formData.seedingScoreFile = "";
    },
    seedingScoreFileOnError(error) {
      this.$message.error("上传失败，请联系管理员!");
    },
    async seedingScoreFileOnSuccess(res) {
      if (res.code === 0) {
        this.formData.seedingScoreFile = res.data
          .replace(/\\/g, "/")
          .split("/")
          .pop();
        this.$message.success("上传成功!");
      } else {
        this.$message.error("上传失败，请联系管理员!");
        this.$refs.seedingScoretemplateUpload.clearFiles();
      }
    },
    removeDashboardFile() {
      this.formData.uploadBrandPriceExcelPath = "";
    },
  },
  watch: {
    flag() {
      // 打开时清空form
      if (this.flag) {
        this.formData = { ...this.initData };
        this.chosenMenu = this.formData.menus;
        if (this.formData.campaignBrandList.length !== 0) {
          this.formData.campaignBrandList.forEach((v) => {
            v.imageUrl = process.env.VUE_APP_API_HOST + v.logoFile;
          });
        }
        // 深拷贝campaignBrandList 解除与tableData的关联
        this.tableData = JSON.parse(
          JSON.stringify(this.formData.campaignBrandList)
        );
        this.checkedAll = false;
        if (this.title === "新增操作员") {
          this.formData.campaignBrand = [];
        }
        // resetFields的作用是恢复默认值，并不是清空
        nextTick(() => {
          this.$refs.formData.clearValidate();
        });
      }
    },
    chosenMenu(val) {
      // 根据品牌榜单判断有无声量
      if (val.includes(320)) {
        this.formData.isSelSov = false;
      } else {
        this.formData.isSelSov = true;
        this.checkedAll = false;
        this.formData.sovBrandList = [];
      }
      // 根据活动管理判断有无报价
      if (val.includes(430)) {
        this.formData.isSelCampaignBrand = false;
      } else {
        this.formData.isSelCampaignBrand = true;
        this.formData.campaignBrandList = [];
      }
      /*
       * 判断菜单中是否包含美妆
       * 包含:可上传文件
       * 不包含:禁用上传/下载模版按钮
       * */
      if (val.includes(610)) {
        this.disabledDashboardUpload = false;
      } else {
        this.disabledDashboardUpload = true;
      }
    },
  },
  components: {
    Plus,
    CloseBold,
  },
  created() {
      this.getMenu()
  }
};
</script>

<style scoped lang="scss">
.adminDialogClass {
  .pLabel {
    color: $color-text-l;
    font-size: 14px;
    font-weight: bold;
    line-height: 15px;
    margin-bottom: 18px;
    border-bottom: 1px solid $color-title-underline;
  }

  .btnDiv {
    text-align: center;
    margin-bottom: 10px;
  }
}
</style>

<style lang="scss">
.adminDialogClass {
  min-width: 1000px;
  .competitionClass .el-input--mini .el-input__inner,
  .brandListClass .el-input--mini .el-input__inner {
    width: 250px;
  }

  .table_container {
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 60px;
      height: 60px;
      text-align: center;
    }

    .avatar-uploader-icon svg {
      margin-top: 16px; /* (178px - 28px) / 2 - 1px */
    }

    .avatar {
      width: 60px;
      height: 60px;
      display: block;
    }

    .closeClass {
      cursor: pointer;
    }

    .avatar-uploader {
      .el-upload-list__item.is-success {
        margin: 0 !important;
      }
    }
  }

  .selectAll.el-checkbox {
    text-align: right;
    width: 100%;
    padding-right: 10px;
    box-sizing: border-box;
  }

  .dashboard_upload {
    width: 300px;

    .el-upload-list {
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;

      .el-upload-list__item {
        margin: 0;
      }

      .el-upload-list__item:first-child {
        margin-top: 10px;
      }
    }
  }

  .el-input-number {
    width: 100%;

    .el-input__inner {
      text-align: left;
    }
  }
}
</style>
